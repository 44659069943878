import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>Tüdel Beats - About</title>
      </Helmet>

      <Layout>
        <section className="section">
          <div className="container">
            <h1 className="title">About Tüdel Beats</h1>
            <p className="content">
              Hey, I'm Patrick aka Tüdel Beats. I've been making music for a
              long time now with a big break in between. It all began with
              electro music, Big Beat and Break Beats, now I'm into Instrumental
              Hip Hop, Lo-Fi and Boombap beats.
            </p>
            <p className="content">
              The foundation of the music I create today is black music and
              black culture. I continously educate myself about black culture
              and history, trying to give back and support as much as possible.
              Fight racism here in Germany and all over the planet!{" "}
              <b>#blacklivesmatter</b>
            </p>
            <p className="content">
              "Tüdel" is a fuzzy northern German word, which means something
              like taking care of things slowly and laboriously, which describes
              my workflow pretty well sometimes. It could also mean "messing up"
              - but I would obviously never do this ;)
            </p>
            <p className="content">
              Searching for Tudel Beats or Tudelbeats, because you've got no
              umlaut "ü" on your keyboard? I got you covered! The correct
              spelling without "ü" is Tuedel Beats though.
            </p>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default AboutPage
